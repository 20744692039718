import React from 'react';
import Helmet from 'react-helmet';
import { Header, Container, Layout } from 'components';
import config from '../../config/website';

const About = () => (
  <Layout>
    <Helmet title={`About | ${config.siteTitle}`} />
    <Header>Legal</Header>
    <Container type="text">
      <h1>Amicusbeef</h1>
      <p>
        You beef, we brief. Let's get it on.
      </p>
    </Container>
  </Layout>
);

export default About;
